<template>
    <div class="min-h-screen flex flex-col">
        <menu-app class="grow-0"></menu-app>

        <Home-pop-up></Home-pop-up>

        <div class="mb-auto grow w-full">
            <slot/>
        </div>

        <footer-app class="grow-0"></footer-app>

        <chat-floating :slightly-up="isMobile"></chat-floating>
    </div>
</template>

<script setup>
import HomePopUp from "~/pages/components/HomePopUp.vue";

const isMobile = ref(useNuxtApp().$isMobile);
</script>

<style scoped lang="scss">

</style>
